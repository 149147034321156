import React from 'react'
import { Card as CardComponent, Box } from 'theme-ui'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import BannerHorizontal from '@widgets/BannerHorizontal'
import BannerVertical from '@widgets/BannerVertical'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import Banner from '../../flow-ui-widgets/Banner/Banner'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      {/* <BannerHorizontal /> */}
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
        <Divider />
      </Stack>
      <Divider space={3} />
      <Hero wide>
        <PostImage {...post} full />
      </Hero>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostBody {...post} />
            {/* <BannerHorizontal /> */}
            {post.category && (
              <Box sx={{ display: ['block', null, 'none'] }}>
                <Divider />
                <CardList
                  title='Tin Khác'
                  nodes={relatedPosts}
                  variant='horizontal-md'
                  limit={5}
                  omitCategory
                  omitMedia
                  omitFooter
                  excerpt
                  distinct
                  aside
                />
              </Box>
            )}
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
          <Divider />
          {/* <BannerHorizontal /> */}
          <Banner slot="4810301642" />
        </Main>
        <Sidebar>
          {/* <AuthorCompact author={post.author} omitTitle /> */}
          {/* <Divider /> */}
          {/* <BannerVertical /> */}
          <Banner slot="3297075271" />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                title='Tin Liên Quan'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={6}
                omitCategory
                omitMedia
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
